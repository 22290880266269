import React from 'react';

export default function Events() {
    return (
        <div className='page-content'>
            <h2>Encampments</h2>
            <p>
                During our on-site presentations, we meticulously recreate a comprehensive
                encampment typical of mercenary companies across various historical periods.

                This immersive experience encompasses communal cooking spaces, individual tents,
                an armory, and dedicated areas for maintenance and sparring. Visitors and students are
                warmly invited to explore our encampment,inspect the displayed weapons and armor, and engage
                with our group to learn more about our equipment and setup. Each member of our group portrays
                a distinct historical character spanning from the Middle Ages to the Renaissance, equipped with
                authentic gear tailored to their specific roles and functions.
            </p>
            <h2>Demonstrations</h2>
            <p>
                The Band of Iron Lions aims to showcase authentic sword fighting to audiences worldwide.
                While choreographed action is entertaining, our group focuses on rigorous training in genuine
                historical fencing techniques dating back to the 14th century. Through our demonstrations,
                we illustrate how these techniques would realistically interact in combat scenarios by skilled
                practitioners.

                Although our swords are not sharpened, they are crafted from high-carbon steel capable of causing
                significant harm. Therefore, safety is paramount, and we employ heightened protective measures,
                particularly around the head and hands, surpassing historical norms. Additionally, we enthusiastically
                introduce our guests to the foundational aspects of our techniques using synthetic training swords
                designed to replicate the weight and feel of real steel swords.

                The techniques showcased on-site represent the foundational teachings imparted to our students during
                their initial days at our sword school. These fundamentals form the cornerstone of understanding
                Historical European Martial Arts. Our demonstrations, meanwhile, highlight advanced skills that
                practitioners develop over time.
            </p>
            <h2>Upcoming events</h2>
            <p>
                We warmly invite you to join us at our upcoming live events. Please find below a schedule of our
                group&apos;s performances. We look forward to your presence!
            </p>
            <div className='centered-bullet-list'>
                <ul>
                    <li>Ames Renaissance Faire - April 12th &amp; 13th, <a href='https://www.strideevents.com/ames-renaissance-faire/event-information'>Visit their website</a></li>
                    <li>Central Missouri Renaissance Festival - April 26th &amp; 27th, May 3rd &amp; 4th, October 18th &amp; 19th, <a href='https://www.centralmorenfest.net/'>Visit their websitee</a></li>
                    <li>Travellers Renaissance Village - May 10th &amp; 11th, <a href='https://www.travellersrenaissancevillage.com/'>Visit their website</a></li>
                    <li>Renaissance Festival of Nebraska - May 17th &amp; 18th, <a href='https://renfestnebraska.com'>Visit their website</a></li>
                    <li>Iowa Renaissance Festival - May 24th - 26th, May 31st &amp; June 1st, October 11th &amp; 12th <a href='https://iowarenfest.com/'>Visit their website</a></li>
                    {/* <li>Nebraska Renaissance Faire - August 23th &amp; 24th <a href='https://nebfaire.com/'>Visit their website</a></li> */}
                    {/* <li>Quad Cities Renaissance Faire - September 27th &amp; 28th, October 4th &amp; 5th <a href='https://quadcitiesrenfaire.com/'>Visit their website</a></li> */}
                </ul>
            </div>
            <h2>Get Us Involved at Your Event</h2>
            <p>
                For contact information to have our group at your event, please email <a href='mailto:contact@bandofironlions.org' target='blank' className='link'>contact@bandofironlions.org</a> or reach out to our facebook!
            </p>
        </div>
    );
}
